export default {
  page: {
    title: {
      base: 'Autopay Zahlungsgateway',
      start: 'Wahl der Zahlungsmethode',
      visadata: 'Visa Mobile',
      continue: 'Weiter - Auswahl einer Zahlungsmethode',
      wait: 'Erwartung',
      confirmation: 'Zahlungsbestätigung',
      thankYou: 'Danke für die Zahlung',
      error: 'Seitenfehler',
      errorNotFound: 'Seite nicht gefunden',
      secureBack: 'Ladeseite',
      maintenance: 'Website wird gewartet',
      regulationsApproval: 'Bestimmungen und Datenschutzbestimmungen',
      toTransferData: 'Banküberweisungsdetails',
      sessionTimeout: 'Seite nach dem Ende der Sitzung',
      paywayError: 'Zahlungskanal nicht verfügbar'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Schriftgröße vergrößern',
      decreaseFontSize: 'Schriftgröße verringern',
      changeContrast: 'Kontrast ändern',
      changeLanguage: 'Sprache der Website ändern',
      skipToContent: 'Springe zum Inhalt',
      skipToContentLabel: 'Zum Hauptinhalt springen',
      logoTitle: 'Website-Logo'
    },
    sessionTimer: {
      timeOut: 'Sitzung ist abgelaufen',
      sessionEnd: 'Sitzung endet in:',
      label: 'Sitzungsdauer verlängern',
      button: 'Verlängern'
    }
  },
  footer: {
    text1: 'Haben Sie weitere Fragen zu Online-Zahlungen oder unseren anderen Dienstleistungen? <a title="Öffnet in einem neuen Fenster" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" class="footer-text-link" target="_blank" rel="noreferrer">Rufen Sie die Hilfeseite auf</a>',
    text2: {
      part1: 'Verantwortliche für die Verwaltung der persönlichen Daten ist Autopay S.A.',
      part2: 'st. Powstańców Warszawy 6, 81-718 Sopot (Polen). Die Angabe personenbezogener Daten ist freiwillig, aber für die Bearbeitung des eingereichten Antrags erforderlich. Die Rechtsgrundlage, der Zweck, die Dauer der Verarbeitung der personenbezogenen Daten und die Rechte des Nutzers sowie weitere wichtige Informationen über die Grundsätze der Verarbeitung personenbezogener Daten sind in der <a href="{privacyPolicyUrl}" class="footer-text-link" target="_blank" title="Gehen Sie zur Seite mit der Datenschutzerklärung (128 KB, PDF)" rel="noreferrer">Datenschutzrichtlinie von Autopay S.A. aufgeführt.</a>'
    },
    linkShowMoreTitle: 'Weitere Informationen anzeigen',
    linkShowMoreText: 'Lese mehr',
    linkShowLessTitle: 'Zusätzliche Informationen ausblenden',
    linkShowLessText: 'Lese weniger'
  },
  paywall: {
    header: 'Zahlungsmethode wählen',
    info: {
      paywayNotAvailable: 'Es tut uns sehr leid, aber es sieht so aus, als ob die gewählte Zahlungsmethode auf Ihrem Gerät im Moment nicht möglich ist.',
      applePayNotAvailable: 'Es tut uns sehr leid, aber es sieht so aus, als ob das Bezahlen mit Apple Pay auf Ihrem Gerät zur Zeit nicht möglich ist.',
      googlePayNotAvailable: 'Es tut uns sehr leid, aber es sieht so aus, als ob das Bezahlen mit Google Pay auf Ihrem Gerät zur Zeit nicht möglich ist.'
    },
    paywayGroup: {
      inactiveMessage: 'Die Zahlung ist derzeit nicht möglich',
      notice: {
        novelty: 'NEU',
        promotion: 'SONDERANGEBOT',
        recommended: 'EMPFOHLEN',
        mastercard: 'SCHNELL UND SICHER',
        blik: 'BLIKOMANIA – anmelden und Diamanten gewinnen!',
        visaMobile: 'Zahlen Sie reibungslos und sicher'
      },
      bank: {
        many: 'banken',
        one: 'bank',
        two: 'banken'
      },
      wallet: {
        many: 'Geldbörsen',
        one: 'Geldbörse',
        two: 'Geldbörse'
      },
      otp: {
        many: 'Wege',
        one: 'Weg',
        two: 'Wege'
      },
      blikPayLater: {
        info: 'Finde mehr heraus'
      }
    },
    paywayList: {
      label: 'Gruppe der Zahlungsarten',
      linkSelectOther: 'Andere Zahlungsart wählen'
    },
    paywaySingle: {
      accountAtAnotherBank: 'Ich habe ein Konto bei einer anderen Bank'
    },
    paywaySwitch: {
      methodShortSingle: 'Wegwerf',
      methodLongSingle: 'Einmalzahlungen',
      methodShortAuto: 'Zyklisch',
      methodLongAuto: 'Wiederkehrende Zahlungen'
    },
    form: {
      payway: {
        label: 'Zahlungsmethode wählen'
      },
      card: {
        label: 'Kartendaten eingeben',
        iframe: {
          title: 'Formular mit Kreditkartenangaben'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Auszufüllendes Formular mit Telefonnummer'
        },
        status: {
          pending: {
            statusText: 'Auf Bestätigung warten',
            header: 'Bestätigen Sie die Zahlung in der App',
            descriptionText1: 'Die Zahlung bestätigen Sie in der App Visa Mobile oder in einer Banking-App mit aktivierter Funktion Visa Mobile',
            descriptionText2: 'Sobald die Transaktion in der App bestätigt wurde, warten Sie das Zahlungsergebnis ab',
            descriptionText3: 'Die Stornierung der Transaktion ist bereits in Bearbeitung',
            cancelButton: 'Zahlung stornieren'
          },
          success: {
            statusText: 'Vielen Dank für Ihren Kauf!',
            descriptionText: 'Zahlung wurde erfolgreich abgeschlossen'
          },
          error: {
            statusText: 'Achten Sie darauf, dass auf der für die Zahlung Visa Mobile verwendeten Zahlungskarte genügend Guthaben vorhanden ist.',
            descriptionText: 'Zahlung wurde abgelehnt'
          }
        },
        apps: 'Apps für Zahlungen via Visa Mobile',
        help: 'Brauchen Sie Hilfe?',
        info: 'Mehr erfahren',
        secure: 'Sichere Bezahlung mit SSL-Verschlüsselung',
        modal2: {
          header: 'Um die Zahlung zu bestätigen, öffnen Sie die App Visa Mobile oder die Banking-App, bei der Sie die Funktion Visa Mobile aktiviert haben',
          listText1: 'Sie haben die App Visa Mobile installiert oder Funktion Visa Mobile in Ihrer Banking-App aktiviert',
          listText2: 'Sie haben Benachrichtigungen in Ihrer App Visa Mobile oder Banking-App mit aktivierter Funktion Visa Mobile aktiviert',
          listText3: 'Die von Ihnen angegebene Telefonnummer ist korrekt: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Achten Sie auch darauf, dass...'
        }
      },
      click2pay: {
        loaderText: 'Szukamy Twojego adresu e-mail<br>w <strong>Click to Pay</strong>',
        saveCard: {
          text1: 'Zapisz swoją kartę',
          text2: 'Twoje dane zostaną bezpiecznie zapisane w <strong>Mastercard Click to Pay</strong>',
          link: 'Dowiedz się więcej'
        },
        email: {
          labelForClick2Pay: 'Adres e-mail do powiadomień o płatności'
        },
        cardSelect: {
          selected: 'Wybrana',
          select: 'Wybierz',
          payOther: 'Wpisz dane karty',
          back: 'Powrót'
        },
        cardSelected: {
          header: 'Użyj karty zapisanej w Click to Pay',
          date: 'ważna do',
          buttonChange: 'Zmień'
        },
        codeForm: {
          description: 'Znaleźliśmy Twój adres e-mail w Click to Pay.',
          codeSentEmail: 'Wpisz jednorazowy kod, który wysłaliśmy na adres {email} w celu zalogowania się na konto.',
          codeSentPhone: 'Wpisz jednorazowy kod SMS, który wysłaliśmy na nr {phone} w celu zalogowania się na konto.',
          codeLabel: 'Podaj kod',
          codePlaceholder: 'podaj kod',
          codeLabelFlow: 'Kod',
          codeSendLabel: 'Wyślij nowy kod',
          codeSendAriaLabel: 'przez',
          codeSendAriaLabel2: 'lub',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'e-mail',
          trustedLabel: 'Dodaj to urządzenie do zaufanych<br><span>Następnym razem rozpoznamy to urządzenie, a Ty zapłacisz bez logowania</span>',
          buttonSubmit: 'Dalej',
          buttonChangeCard: 'Wprowadź dane karty'
        },
        info: {
          description: 'Płatność kartą jest zaszyfrowana i bezpieczna. Transakcja zostanie autoryzowana za pomocą 3DSecure na stronie banku.'
        },
        loginForm: {
          emailLabel: 'Podaj adres e-mail, aby zalogować się do Click to Pay',
          emailPlaceholder: 'adres e-mail',
          emailLabelFlow: 'E-mail',
          buttonSubmit: 'Zaloguj się',
          buttonBack: 'Powrót'
        },
        newUserForm: {
          header: 'Zapisz kartę w Click to Pay',
          description: 'Potrzebujemy jeszcze kilku informacji, by zapisać Twoją kartę.',
          emailLabel: 'Podaj adres e-mail',
          emailPlaceholder: 'adres e-mail',
          emailLabelFlow: 'E-mail',
          countryLabel: 'Kraj',
          firstnameLabel: 'Imię',
          firstnamePlaceholder: 'imię',
          firstnameLabelFlow: 'Imię',
          lastnameLabel: 'Nazwisko',
          lastnamePlaceholder: 'nazwisko',
          lastnameLabelFlow: 'Nazwisko',
          phoneLabel: 'Numer telefonu',
          phonePlaceholder: 'numer telefonu',
          phoneLabelFlow: 'Telefon',
          countryCodePlaceholder: 'kod',
          countryCodeLabelFlow: 'Kod',
          trustedLabel: 'Dodaj to urządzenie do zaufanych<br><span>Następnym razem rozpoznamy to urządzenie, a Ty zapłacisz bez logowania</span>',
          termsLabel: 'Kontynuując, akceptujesz <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Warunki korzystania ze strony</a> i&nbsp;rozumiesz, że Twoje dane będą przetwarzane zgodnie z <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank">Polityką prywatności</a> Mastercard.',
          c2pInfo: 'Click to Pay użyje tych informacji, aby Cię zweryfikować i wysyłać na ten numer kody weryfikacyjne. Mogą zostać naliczone opłaty za wiadomości/transmisję danych.',
          buttonSubmit: 'Zapisz i zapłać',
          buttonBack: 'Zapłać bez zapisywania karty'
        },
        profile: {
          header: 'Zaloguj się do Click to Pay',
          buttonChangeEmail: 'Zmień adres e-mail'
        },
        modal: {
          title: 'Click to Pay - informacje',
          header: 'Płać szybko i bezpiecznie, bez przepisywania danych karty w nowym standardzie Click to Pay',
          text1: 'Podczas płatności kartą szukaj logo <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> i płać kartą płatniczą szybko i wygodnie.',
          text2: 'Click To Pay to nowy standard płatności kartą wspierany przez Visa i Mastercard.',
          text3: 'Kartą na koncie Click to Pay zapłacisz w sklepach w Polsce i za granicą, bez przepisywania danych z karty.',
          text4: 'Dane zapisywane są bezpiecznie w Visa lub Mastercard. Wszystkie informacje są w pełni szyfrowane.',
          text5: 'Podczas kolejnych płatności Click to Pay rozpozna Twój adres e-mail, dzięki czemu od razu zapłacisz zapisanymi kartami.',
          text6: 'Płatności potwierdzasz dodatkowo w swoim banku, co gwarantuje pełne bezpieczeństwo.',
          processError: 'W trakcie wykonywania płatności Click to Pay wystąpił błąd. Wykonaj standardową płatność kartą.'
        }
      },
      email: {
        label: 'E-Mail-Adresse eingeben',
        flowLabel: 'E-mail',
        tip: 'Wir benötigen Ihre E-Mail-Adresse, damit wir Ihnen Nachrichten über Ihren Zahlungsstatus schicken können.'
      },
      terms: {
        header: 'Einwilligungen und Erklärungen',
        selectAll: 'Alle markieren',
        fieldNotRequired: 'Freiwillig',
        partnerMarketingTextLess: 'Möchten Sie von uns per E-Mail, SMS oder Telefon über die besten Angebote, Aktionen, Wettbewerbe und andere interessante Veranstaltungen informiert werden, die von uns oder unseren Partnern organisiert werden?',
        partnerMarketingTextMore: 'Wir versprechen keinen Spam! Wenn Sie von unseren Nachrichten gelangweilt sind, können Sie Ihre Zustimmung jederzeit widerrufen.',
        tradeMarketingTextLess: 'Möchten Sie, dass wir Ihre E-Mail-Adresse oder Telefonnummer an unsere vertrauenswürdigen Partner weitergeben, damit diese Ihnen interessante Informationen und Angebote zukommen lassen können?',
        tradeMarketingTextMore: 'Bitte seien Sie versichert, dass es sich bei unseren Partnern um renommierte Unternehmen handelt, die ebenso wie wir keinen Spam akzeptieren, und dass Sie Ihre Zustimmung jederzeit widerrufen können.'
      },
      termsLabelDefault: 'Ich habe gelesen und akzeptiere: <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Allgemeine Geschäftsbedingungen herunterladen" rel="noreferrer">Allgemeine Geschäftsbedingungen</a> für Zahlungsdienste und <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Datenschutzbestimmungen von Autopay S.A. herunterladen">Datenschutzbestimmungen</a>. Ich möchte, dass der Zahlungsservice sofort ausgeführt wird, und im Falle eines Rücktritts vom Vertrag weiß ich, dass ich bis zum Rücktritt vom Vertrag auf mein Verlangen keine Erstattung der Kosten für die erbrachten Dienstleistungen erhalte.',
      termsReccuring: 'Wenn Sie eine Zahlung leisten möchten, akzeptieren Sie bitte die <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Allgemeine Geschäftsbedingungen herunterladen" rel="noreferrer">Allgemeinen Geschäftsbedingungen</a> und <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Datenschutzbestimmungen von Autopay S.A. herunterladen">Datenschutzbestimmungen</a> von Autopay S.A., die Zustimmung zur sofortigen Ausführung des Zahlungsdienstes und die Zustimmung zur Belastung Ihrer Kreditkarte',
      termsReccuringMore1: 'Ich bin damit einverstanden, dass die Zahlung sofort ausgeführt wird. Ich bin mir im Klaren darüber, dass bei einem Rücktritt vom Vertrag keine Rückerstattung der für die auf meinen Wunsch ab Vertragsrücktritt erbrachten Dienstleistungen anfallenden Kosten erfolgt.',
      termsReccuringMore2: 'Ich erkläre mich damit einverstanden, dass meine Zahlungskarte regelmäßig von Autopay S.A. zur Abwicklung von Transaktionen für {receiverName} belastet werden kann. Die Bestimmungen für die zyklische Abbuchung von der Karte sind in <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Allgemeine Geschäftsbedingungen herunterladen" rel="noreferrer">Allgemeinen Geschäftsbedingungen</a> für Zahlungsdienste festgelegt. Ich erkläre, dass mir die Fristen, die Häufigkeit und die Bestimmungen für die Festlegung der Beträge, mit denen meine Zahlungskarte belastet wird, sowie die Bedingungen für den Widerruf dieser Zustimmung, die ich mit {receiverName} vereinbart habe, bekannt sind. Bei Fragen zur wiederkehrenden Belastung der Zahlungskarte wende ich mich an {receiverName}.',
      mwfClauseLess: 'Wenn Sie eine Zahlung vornehmen möchten, akzeptieren Sie <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Allgemeine Geschäftsbedingungen (AGB)" rel="noreferrer">Regeln</a> und  <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Privatsphäre Autopay S.A.">die Datenschutzbestimmungen</a> von Autopay S.A. und stimmen der sofortigen Ausführung des Zahlungsdienstes zu.',
      mwfClauseMore: 'Ich bin damit einverstanden, dass die Zahlung unverzüglich ausgeführt wird. Ich bin mir im Klaren darüber, dass mir bei einem Rücktritt vom Vertrag keine Kosten erstattet werden.',
      mwfClauseARIA: 'Wenn Sie eine Zahlung vornehmen möchten, akzeptieren Sie Regeln und die Datenschutzbestimmungen von Autopay S.A. und stimmen der sofortigen Ausführung des Zahlungsdienstes zu. Ich bin damit einverstanden, dass die Zahlung unverzüglich ausgeführt wird. Ich bin mir im Klaren darüber, dass mir bei einem Rücktritt vom Vertrag keine Kosten erstattet werden. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Laden Sie die Datei mit Regeln</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Laden Sie die Datei mit die Datenschutzbestimmungen</a>',
      paymentInfo: 'Ein Zahlungsauftrag über Autopay S.A. mit Sitz in Sopot wird gemäß den von Ihrer Bank festgelegten Bedingungen ausgeführt. Sobald Sie Ihre Bank ausgewählt haben, werden Sie die Zahlung autorisieren.',
      changePaywayLink1: 'Sie können',
      changePaywayLink2: 'Ihre Zahlungsmethode noch ändern',
      linkMore: 'MEHR',
      linkShowMoreTitle: 'Zusätzliche Informationen ansehen',
      linkLess: 'WENIGER',
      linkShowLessTitle: 'Zusätzliche Informationen ausblenden',
      submitButton: {
        visaMobile: 'Zahlen nach',
        fastTransfer: 'Überweisungsdetails erzeugen',
        mwf: 'Ich akzeptiere und bezahle',
        pis: 'Ich akzeptiere und beginne den Zahlungsvorgang',
        auto: 'Wiederkehrende Zahlung bestellen',
        autoPayment: 'Zahlung bestellen',
        other: 'Bezahlen',
        orange: 'Bestätigen',
        c2pNext: 'Dalej',
        c2pAccept: 'Akceptuję',
        c2pSaveAndPay: 'Zapisz i zapłać'
      }
    },
    frData: {
      pageTitle: 'Detaillierte Daten für die Überweisung',
      header: 'Überweisung unter Verwendung der angegebenen Daten anfordern',
      nrbLabel: 'Konto des Empfängers',
      nameLabel: 'Name des Empfängers',
      addressLabel: 'Adresse des Empfängers',
      titleLabel: 'Verwendungszweck',
      amountLabel: 'Überweisungsbetrag',
      timeLabel: 'Bis zum X die Transaktion durchführen',
      linkCopy: 'Kopieren',
      linkGetPdf: 'PDF mit Daten herunterladen',
      linkGetPdfTitle: 'Öffnet in einem neuen Fenster',
      linkGoToBank: 'Bei Ihrer Bank anmelden',
      info: 'Loggen Sie sich in Ihr Bankkonto ein. Kopieren Sie die Angaben in das Überweisungsformular. Es ist wichtig, dass die Angaben identisch bleiben, bitte ändern Sie nicht den Betrag, die Währung, den Verwendungszweck usw.'
    },
    processing: {
      pageTitle: 'Warteseite für die Transaktionsverarbeitung',
      loaderAnimation: 'Während des Wartens wird eine Animation angezeigt',
      header: 'Wir bereiten Ihre Zahlungsseite vor.',
      description: 'Noch ein bisschen Geduld...',
      info: {
        header: 'Hinweis!',
        description: 'Schließen Sie die Seite nicht, nachdem Sie die Transaktion abgeschlossen haben - sonst wird Ihr Geld nicht an den Empfänger gesendet.'
      },
      loaderText: 'Die Datenübertragung läuft.<br>Es dauert höchstens 30 Sekunden.'
    }
  },
  thankYou: {
    pageTitle: 'Informationen über das Ergebnis der Transaktion',
    form: {
      emailLabel: 'E-mail',
      buttonSubmit: 'Zurück zur Website des Partners'
    },
    negative: {
      header: 'Die Zahlung wurde nicht bearbeitet, weil die Transaktion nicht autorisiert wurde.',
      description: 'Wenn Sie dennoch das Angebot eines bestimmten Händlers oder Dienstleisters nutzen möchten, gehen Sie zurück auf dessen Website, wählen Sie das Produkt oder die Dienstleistung aus und zahlen Sie mit der von Ihnen gewünschten Zahlungsmethode'
    },
    pending: {
      header: 'Vielen Dank, dass Sie die Zahlung über das Zahlungsportal vorgenommen haben.',
      description: 'Die Überprüfung des Transaktionsstatus ist im Gange. Sobald der Vorgang abgeschlossen ist, erhalten Sie Informationen über den Status Ihrer Zahlung in Ihrem E-Mail-Postfach.'
    },
    positive: {
      header: 'Vielen Dank! Ihre Zahlung wurde bearbeitet',
      description: 'Wir senden eine Bestätigung an die von Ihnen angegebene E-Mail-Adresse.'
    }
  },
  regulationsApproval: {
    header: 'Akzeptieren Sie die Transaktion'
  },
  wait: {
    pageTitle: 'Warteseite'
  },
  sessionTimeout: {
    pageTitle: 'Seite nach dem Ende der Sitzung',
    header: 'Die Frist für den Abschluss der Zahlungen ist abgelaufen',
    description: 'Sie können jederzeit auf die Website des Partners zurückkehren, Ihre Bestellung erneut aufgeben oder den Service nutzen und die Zahlung mit der von Ihnen gewählten Zahlungsmethode vornehmen.'
  },
  paywayError: {
    pageTitle: 'Der ausgewählte Zahlungskanal ist nicht verfügbar',
    header: 'Leider ist die ausgewählte Zahlungsmethode derzeit nicht verfügbar.',
    description: 'Bitte kehren Sie zur Partnerseite zurück und versuchen Sie es später erneut.'
  },
  loader: {
    defaultAnimation: 'Animation, die angezeigt wird, während die Seite geladen wird',
    slider: 'Slider mit wechselnden Texten',
    text1: 'Wir streben die Kohlenstoffneutralität an. Mit unseren Dienstleistungen tun Sie das auch.',
    text2: 'Wir fahren mit dem Fahrrad zur Arbeit und reduzieren so die Treibhausgasemissionen in die Atmosphäre. Mit unseren Dienstleistungen tun Sie das auch.',
    text3: 'Wir werden mit grüner Energie betrieben. Mit unseren Dienstleistungen tun Sie das auch.',
    text4: 'Wir reduzieren den Papierverbrauch. Mit unseren Dienstleistungen tun Sie das auch.'
  },
  orderDetails: {
    header: 'Details',
    headerAriaLabel: 'Transaktionsdetails',
    amountLabel: 'Betrag',
    receiverLabel: 'Empfänger',
    orderLabel: 'Auftragsnummer',
    amountOryginalCurrencyLabel: 'Betrag in {currency}',
    currencyRateLabel: 'Tauschrate',
    feeLabel: 'Gebühr für die Transaktionsverarbeitung',
    customerNumberLabel: 'Kundennummer',
    invoiceNumberLabel: 'Rechnungsnummer',
    accountNumberLabel: 'Kontonummer',
    paywayLabel: 'Zahlungsmethode',
    paywayName: 'Online-Banküberweisung',
    linkBack: 'Zurück zum Shop',
    linkBackTitle: 'Verlassen Sie die Transaktion und kehren Sie zum Geschäft zurück',
    linkMore: 'mehr',
    linkLess: 'weniger',
    toggleBtnUnfoldAriaLabel: 'Auftragsdetails erweitern',
    toggleBtnFoldAriaLabel: 'Auftragsdetails ausblenden'
  },
  error: {
    pageTitle: 'Fehlerseite',
    linkBackUrlSet: 'Zurück zur Website des Partners',
    linkBackUrlNotSet: 'Zur Hilfeseite',
    sessionTimeout: {
      title: 'Die Frist für den Abschluss der Zahlungen ist abgelaufen',
      message: 'Sie können jederzeit auf die Website des Partners zurückkehren, Ihre Bestellung erneut aufgeben oder den Service nutzen und die Zahlung mit der von Ihnen gewählten Zahlungsmethode vornehmen.'
    },
    notFoundError: {
      pageTitle: 'Fehlerseite 404 - Seite nicht gefunden',
      title: 'Es scheint, dass Sie zu weit gesegelt sind und die Website, die Sie besuchen möchten, nicht gefunden wurde.',
      message: 'Mögliche Gründe: Die Seite existiert nicht oder wurde gelöscht, es finden Wartungsarbeiten statt, die Adresse der Seite ist falsch, es ist ein technischer Fehler aufgetreten.'
    },
    generalError: {
      title: 'Es tut uns leid.',
      message: 'Wir sind derzeit nicht in der Lage, Ihre Überweisung zu bearbeiten'
    },
    errFieldNotFound: {
      title: 'Es tut uns leid',
      message: ''
    },
    errBadClientSource: {
      title: 'Es tut uns leid',
      message: ''
    },
    nrParametersError: {
      title: 'Es tut uns leid',
      message: 'Wir sind derzeit nicht in der Lage, Ihre Überweisung zu bearbeiten.'
    },
    transactionOutdated: {
      title: 'Die Frist für den Abschluss der Zahlungen ist abgelaufen',
      message: 'Fordern Sie die Zahlung erneut an.'
    },
    linkValidityTimeOutdated: {
      title: 'Die Frist für den Abschluss der Zahlungen ist abgelaufen',
      message: 'Fordern Sie die Zahlung erneut an.'
    },
    transactionValidityTimeOutdated: {
      title: 'Die Frist für den Abschluss der Zahlungen ist abgelaufen.',
      message: 'Fordern Sie die Zahlung erneut an.'
    },
    multiplyTransaction: {
      title: 'Es tut uns leid',
      message: 'Die Transaktion existiert bereits und wartet auf die Zahlung.'
    },
    transactionCanceled: {
      title: 'Es tut uns leid.',
      message1: 'Die Transaktion wurde storniert.',
      message2: 'Fordern Sie die Zahlung erneut an.'
    },
    multiplyPaidTransaction: {
      title: 'Es tut uns leid',
      message: 'Die Transaktion is bereits bezahlt.'
    },
    transactionReject: {
      title: 'Es tut uns leid',
      message1: 'Ups, da ist etwas schief gelaufen',
      message2: 'Wir sind bereits dabei, unser Zahlungsportal zu reparieren...'
    },
    bankDisabled: {
      title: 'Es tut uns leid.',
      message: 'Die ausgewählte Bank ist derzeit nicht verfügbar.'
    },
    bankTemporaryMaintenance: {
      title: 'Es tut uns leid',
      message: 'Wir können Ihre Überweisung zur Zeit nicht bearbeiten.'
    },
    insufficientStartAmount: {
      title: 'Es tut uns leid',
      message: 'Der Höchstbetrag für die Überweisung wurde überschritten.'
    },
    startAmountOutOfRange: {
      title: 'Es tut uns leid',
      message: 'Der Höchstbetrag für die Überweisung wurde überschritten.'
    },
    nonAccountedLimitExceeded: {
      title: 'Es tut uns leid',
      message: 'Die Zahlung kann nicht bearbeitet werden, da der Verkäufer das monatliche Verkaufslimit erreicht hat.'
    }
  },
  confirmation: {
    pageTitle: 'Informationen zum Zahlungsfortschritt',
    linkBack: 'Ich gehe zurück zur Seite',
    linkBackTimeout: 'Zurück zur Website des Partners in ',
    incorrectAmount: {
      header: 'Falscher Betrag',
      description: 'Wir haben Ihre Zahlung nicht an den Empfänger überwiesen, da der Betrag falsch war.',
      description2: 'Klicken Sie auf die Schaltfläche, um zur Seite zurückzukehren und sie zu wiederholen. Denken Sie daran, keine Daten zu ändern.'
    },
    multiplePaid: {
      header: 'Doppelte Einzahlung',
      description: 'Der Empfänger hat Ihre Zahlung zweimal erhalten. Machen Sie sich keine Sorgen – Sie erhalten Ihr Geld zurück.',
      description2: 'Kontaktieren Sie den Empfänger und geben Sie ihm eine Zahlungsbestätigung.'
    },
    negative: {
      header: 'Ende der Zeit',
      description: 'Wir haben Ihre Zahlung nicht an den Empfänger überwiesen, da die Bestätigungszeit abgelaufen ist.',
      description2: 'Klicken Sie auf die Schaltfläche, um zur Seite zurückzukehren und den Vorgang zu wiederholen.'
    },
    notFound: {
      header: 'Keine Zahlung',
      description: 'Bei der Verarbeitung Ihrer Zahlung ist ein Fehler aufgetreten. Aber nichts ist verloren.',
      description2: 'Klicken Sie auf die Schaltfläche, um zur Seite zurückzukehren und den Vorgang zu wiederholen.'
    },
    outdated: {
      header: 'Ende der Zeit',
      description: 'Wir haben Ihre Zahlung nicht an den Empfänger weitergeleitet.',
      description2: 'Klicken Sie auf die Schaltfläche, um zur Seite zurückzukehren und den Vorgang zu wiederholen.'
    },
    outdatedPaidNotRefunded: {
      header: 'Ende der Zeit',
      description: 'Wir haben Ihre Zahlung nicht an den Empfänger weitergeleitet. Wenn Geld von Ihrem Konto abgebucht wurde, machen Sie sich keine Sorgen, Sie erhalten Ihre Rückerstattung innerhalb weniger Tage.',
      description2: 'Klicken Sie auf die Schaltfläche, um zur Seite zurückzukehren und den Vorgang zu wiederholen.'
    },
    outdatedPaidRefunded: {
      header: 'Ende der Zeit',
      description: 'Wir haben Ihre Zahlung nicht an den Empfänger überwiesen. Wenn das Geld von Ihrem Konto abgebucht wurde – keine Sorge, wir haben es bereits zurückerstattet.',
      description2: 'Klicken Sie auf die Schaltfläche, um zur Seite zurückzukehren und den Vorgang zu wiederholen.'
    },
    pending: {
      header: 'In Bearbeitung',
      description: 'Wir bearbeiten Ihre Zahlung. Wir benachrichtigen Sie, sobald es beim Empfänger eintrifft.'
    },
    positive: {
      header: 'Bezahlt',
      visaVideo: 'Werbevideo, das nach einer erfolgreichen Transaktion abgespielt wird',
      description: 'Wir haben Ihr Geld an den Empfänger überwiesen.'
    }
  },
  messages: {
    accessForbidden: 'Kein Zugang',
    internalServerError: 'Es ist ein unerwarteter Fehler aufgetreten. Versuchen Sie es später noch einmal',
    validators: {
      required: 'Pflichtfeld',
      emailRequired: 'Geben Sie eine gültige E-Mail-Adresse ein',
      emailNotValid: 'Geben Sie eine gültige E-Mail-Adresse ein',
      emailNotRecognized: 'Podany e-mail nie jest zarejestrowany',
      emailIdentityLookup: 'Nie udało się zweryfikować adresu e-mail',
      emailLocked: 'Podany e-mail jest tymczasowo zablokowany w systemie Mastercard',
      paywayRequired: 'Wählen Sie zum Bezahlen eine Bank aus',
      creditCardRequired: 'Füllen Sie alle Felder aus',
      phoneRequired: 'Bitte geben Sie eine gültige Telefonnummer ein',
      regulationRequired: 'Akzeptieren Sie die Bedingungen und Konditionen',
      requiredConsent: 'Einwilligung erforderlich',
      codeNotValid: 'Podany kod nie jest prawidłowy',
      codeAccountLocked: 'Konto zostało tymczasowo zablokowane w systemie Mastercard'
    },
    validatorsAria: {
      required: 'Warnung. Das Formularfeld wurde nicht ausgefüllt. Pflichtfeld',
      emailRequired: 'Warnung. Das E-Mail-Feld wurde nicht ausgefüllt. Geben sie eine gültige E-Mail-Adresse an',
      emailNotValid: 'Warnung. Ihre E-Mail-Adresse enthält einen Fehler. Geben sie eine gültige E-Mail-Adresse an',
      emailNotRecognized: 'Uwaga. Podany e-mail nie jest zarejestrowany.',
      emailIdentityLookup: 'Uwaga. Nie udało się zweryfikować podanego adresu e-mail',
      emailLocked: 'Uwaga. Podany e-mail jest tymczasowo zablokowany w systemie Mastercard.',
      paywayRequired: 'Warnung. Zahlung wurde nicht ausgewählt. Wählen Sie Ihre Zahlungsmethode',
      creditCardRequired: 'Warnung. Füllen Sie alle Felder mit Kreditkartendaten aus',
      phoneRequired: 'Hinweis. Das Feld Telefonnummer enthält einen Fehler. Bitte geben Sie die richtige Telefonnummer ein.',
      codeNotValid: 'Uwaga. Podany kod nie jest prawidłowy.',
      codeAccountLocked: 'Uwaga. Konto zostało tymczasowo zablokowane w systemie Mastercard.'
    },
    success: {
      copy: 'Es wurde erfolgreich kopiert!',
      codeSent: 'Kod został wysłany!'
    },
    errors: {
      getPdf: 'Der Download der PDF-Datei ist fehlgeschlagen!',
      copy: 'Es wurde nicht erfolgreich kopiert!',
      thankYouUpdate: 'Die E-Mail Adresse konnte nicht erfolgreich gespeichert werden',
      groupInactive: 'Derzeit ist es nicht möglich, eine Übertragung über die Gruppe {groupName} durchzuführen. Bitte wählen Sie eine andere Zahlungsmethode.',
      paywayInactive: 'Diese Zahlungsmethode ist derzeit nicht verfügbar. Wählen Sie eine andere Zahlungsmethode.',
      googlePayInactive: 'Leider ist die Zahlung mit Google Pay auf Ihrem Gerät nicht verfügbar.',
      applePayInactive: 'ApplePay erfordert den Safari-Browser und ein Apple-Gerät, das mit dieser Zahlungsmethode kompatibel ist.',
      codeNotSent: 'Nie udało się wysłać ponownie kodu!',
      codeCounterExceeded: 'Przekroczono dozwoloną liczbę prób. Wprowadź dane karty ręcznie lub skorzystaj z innej metody płatności.'
    }
  },
  modal: {
    closeButton: 'Schließen',
    confirmButton: 'Ich verstehe'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Wiederkehrende Zahlung',
        description: 'Geben Sie die Details der Karte ein, die Sie für die Zahlung verwenden werden. Automatische Zahlungen werden später von Ihrer Karte abgebucht.',
        labelInitWithRefund: 'Bestätigungszahlung',
        labelInitWithRefundOrange: 'Fügen Sie eine Zahlungskarte hinzu',
        labelInitWithPayment: 'Kartenzahlung',
        descriptionInitWithRefund: 'Geben Sie die Angaben zur Karte ein, die Sie für zukünftige Zahlungen überprüfen lassen möchten. Im nächsten Jahr berechnen wir 1 PLN von der angegebenen Karte,  um die Richtigkeit der Daten zu überprüfen. Nach der Prüfung wird der Betrag von uns erstattet.',
        descriptionInitWithPayment: 'Geben Sie die Angaben zur Karte ein, mit der Sie die Transaktion ausführen und die Sie zugleich für künftige Zahlungen überprüfen lassen.'
      },
      blik: {
        label: 'BLIK',
        description: 'Bezahlen Sie sofort mit BLIK, ohne sich anzumelden oder zu registrieren'
      },
      card: {
        label: 'Bezahlung mit Karte',
        description: 'Bezahlen Sie mit Ihrer Geldkarte'
      },
      pbl: {
        label: 'Online-Überweisung',
        description: 'Wählen Sie die Bank aus, von der Sie eine Zahlung vornehmen möchten',
        descriptionVerify: 'Online-Identitätsprüfung',
        descriptionServiceVerify: 'Wählen Sie die Bank aus, die Ihnen erlaubt, den Online-Service zu verifizieren'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Visa Mobile-Zahlung'
      },
      wallet: {
        label: 'Virtuelles Wallet',
        description: 'Bezahlung, ohne sich beim Online-Banking anmelden zu müssen'
      },
      installments: {
        label: 'Ratenzahlung',
        description: ''
      },
      alior: {
        descriptionInfo1: 'Bezahlen Sie Ihre Einkäufe bequem in Raten',
        descriptionInfo2: '(Details prüfen)',
        aliorModal: {
          text1: 'Abhängig von den Einstellungen der Website, auf der Sie den Kauf tätigen, können Sie Produkte in Raten in den folgenden Varianten erwerben:',
          text2: '<ul><li>10 Raten 0%</li><li>20 Raten 0%</li><li>von 3 bis 48 Raten mit monatlichen Kosten von 1% (gemäß dem angegebenen repräsentativen Beispiel) für eine bestimmte Transaktion)</li></ul>'
        }
      },
      otp: {
        label: 'Spätere Bezahlung',
        description: 'Jetzt kaufen, später bezahlen',
        descriptionInfo1Mwf: 'Bezahlen Sie die Rechnung nachträglich – bis zu 45 Tage am Stück oder in mehreren gleichen Raten',
        descriptionInfo1Ecommerce: 'Zahlen Sie später – bis zu 45 Tage am Stück oder in mehreren gleichen Raten',
        descriptionInfo2Mwf: 'Informationen über den Kreditvermittler und die Kosten',
        descriptionInfo2Ecommerce: 'Kosteninformationen',
        labelPayka: 'Payka',
        descriptionPayka: 'Bezahlen Sie die Rechnung nachträglich – einmalig nach 30 Tagen oder in 4 identischen Raten <a href="" id="paykaShowInModal">Informationen über den Kreditvermittler und die Kosten</a>',
        descriptionBlikPayLater: 'Der Service ist bei Millenium Bank und VeloBank verfügbar',
        paykaModalMwf: {
          header1: 'Information zum Kreditvermittler',
          text1: 'Autopay S.A. mit Geschäftssitz in Sopot ist als Kreditvermittler Paytree S.A. mit Geschäftssitz in Gdańsk (Kreditgeber) tätig und ist zur Präsentation des Kreditangebotes den Kunden sowie zum Weiterleiten zur Website des Kreditgebers inkl. Kreditantrag berechtigt. Details zum Angebot: <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Angaben zu Kosten',
          text2: 'Zahlen Sie später – bis zu 45 Tage am Stück oder in mehreren gleichen Raten. Kostendaten für ein repräsentatives Beispiel: Rückzahlung innerhalb von 45 Tagen effektiver Jahreszins 0 %; Ratenrückzahlung effektiver Jahreszins 86,83 %. Details zum Angebot unter: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Angaben zu Kosten',
          text2: 'Zahlen Sie später – bis zu 45 Tage am Stück oder in mehreren gleichen Raten. Kostendaten für ein repräsentatives Beispiel: Rückzahlung innerhalb von 45 Tagen effektiver Jahreszins 0 %; Ratenrückzahlung effektiver Jahreszins 86,83 %. Details zum Angebot unter: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        blikPayLaterModal: {
          header1: 'Jetzt kaufen, in 30 Tagen zahlen',
          header2: 'BLIK-Code',
          text2: 'Sie geben den BLIK-Code ein und wir bieten Ihnen ein Einkaufslimit von bis zu 4.000 PLN',
          header3: 'Sie kaufen Produkte und bezahlen sie später',
          text3: 'Sie haben Zeit zu prüfen, ob alles zu Ihnen passt',
          header4: 'Sie zahlen innerhalb von 30 Tagen kostenfrei',
          text4: 'Sie können Ihre Einkäufe auch innerhalb der vom Geschäft angegebenen Frist zurückgeben'
        }
      },
      companyPayments: {
        label: 'Firmenzahlungen',
        description: 'Verschieben Sie den Zahlungstermin für geschäftliche Einkäufe um 30 Tage.'
      },
      transfer: {
        label: 'Angaben zur Überweisung',
        description: 'Bestellen Sie eine Überweisung unter Verwendung der angegebenen Daten'
      },
      other: {
        label: 'Sonstiges',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Es tut uns leid. Wir sind derzeit nicht in der Lage, Ihre Überweisung zu bearbeiten. Die gewählte Bank ist derzeit nicht verfügbar.',
        blockMultipleTransactions: 'Es tut uns leid. Wir sind derzeit nicht in der Lage, Ihre Überweisung zu bearbeiten. Die gewählte Bank ist derzeit nicht verfügbar',
        blockPaidTransactions: 'Es tut uns leid. Die Transaktion wurde bereits bezahlt. Sie können die Zahlung nicht noch einmal vornehmen',
        canceledTransactions: 'Die Transaktion, die Sie zu bezahlen versuchen, wurde storniert und ist nicht verfügbar. Bitte starten Sie eine neue Transaktion',
        declined: 'Ablehnung der Autorisierung.',
        generalError: 'Es gab ein temporäres Problem mit der Verbindung. Derzeit können wir Ihre Überweisung nicht bearbeiten. Versuchen Sie es später',
        insufficientStartAmount: 'Es tut uns leid. Der Höchstbetrag für die Überweisung wurde überschritten.',
        internalServerError: 'Upsss.. es hat nicht geklappt :(. Wir arbeiten an einer Lösung für dieses Problem. Versuchen Sie es später noch einmal.',
        nonAccountedLimitExceeded: 'Die Zahlung kann nicht bearbeitet werden, weil der Verkäufer das monatliche Verkaufslimit erreicht hat.',
        outdatedError: 'Die Zeit für den Abschluss der Zahlungen ist abgelaufen..',
        paid: 'Die Transaktion wurde erfolgreich abgeschlossen.',
        paywayLoaded: 'Die Seite „Schnelle Überweisung“ wurde geladen',
        paywaylistLoaded: 'Die Seite zur Auswahl der Zahlungsmethode wurde geladen'
      }
    }
  },
  maintenance: {
    text1: 'Das Zahlungsportal von Autopay wird derzeit auf Vordermann gebracht, so dass es derzeit nicht möglich ist, eine Zahlung zu veranlassen. Wir entschuldigen uns für die Unannehmlichkeiten',
    text2: 'Das Zahlungsportal wird am 16. Januar um 10 Uhr wieder in Betrieb genommen.',
    text3: 'Vielen Dank für Ihr Verständnis.'
  }
}
